import { ContentfulEntry, EntryRef } from "frontend/contentful/schema/sys";
import { useContentful } from "frontend/hooks/use-contentful";
import { ComponentType, ReactNode } from "react";
import { CreateEditAttributesFn } from "./edit-attributes-factory";

export interface ContentfulComponentProps<
  E extends ContentfulEntry,
  O = Record<string, unknown>,
> {
  entry: E;
  options: O | undefined;
  createEditAttributes: CreateEditAttributesFn;
}

export type ContentfulComponent<E extends ContentfulEntry, O> = (
  props: ContentfulComponentProps<E, O>,
) => ReactNode;
export interface ContentfulComponentRenderProps<
  E extends ContentfulEntry,
  O = void,
> {
  entry?: EntryRef<E>;
  options?: O;
}
export type ContentfulFactoryComponent<
  E extends ContentfulEntry,
  O = void,
> = ComponentType<ContentfulComponentRenderProps<E, O>>;

export function createContentfulComponent<E extends ContentfulEntry, O = void>(
  Component: ContentfulComponent<E, O>,
) {
  const ContentfulComponent: ContentfulFactoryComponent<E, O> = ({
    entry: ref,
    options,
  }) => {
    const { getEntry, createEditAttributes } = useContentful();

    const entry = getEntry(ref);

    if (!entry?.fields || !entry?.metadata || !entry?.sys.id) return null;

    return (
      <Component
        entry={entry}
        options={options}
        createEditAttributes={createEditAttributes}
      />
    );
  };

  return ContentfulComponent;
}
